const routePaths = {
  public: {
    home: '/',
    aerial: '/aerial',
    environment: '/environment',
    solarSolutions: '/solar-solutions',
    graph: '/graph',
    weeklyGraph: '/weekly-graph',
    monthlyGraph: '/monthly-graph',
    annualGraph: '/annual-graph',
    singleDash: '/site/:id',
    sites: '/sites',
    systemInfo: '/system-info',
    dashboard: '/dashboard',
    map: '/map',
    howto: {
      sunSolarEnergy: 'sun-solar-energy',
      panelInverter: 'panel-inverter',
      inverterMeter: 'inverter-meter',
      meterGrid: 'meter-grid',
      summary: 'summary',
      landing: 'howto/*'
    }
  }
};

export default routePaths;
