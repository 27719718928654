export enum EQUIVALENCES {
  TREES = 'Trees',
  HOMES = 'Homes',
  GAS = 'Gas',
  CARBON = 'Carbon',
  CARBONDIOXIDE = 'Carbon Dioxide'
}

export const EQUIVALENCE_UNITS = {
  [EQUIVALENCES.TREES]: 'trees',
  [EQUIVALENCES.HOMES]: 'homes',
  [EQUIVALENCES.GAS]: 'gallons',
  [EQUIVALENCES.CARBON]: 'pounds',
  [EQUIVALENCES.CARBONDIOXIDE]: 'tons'
};
