export enum ACTIONS {
  // App state actions.
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  CLEAR_ERROR = 'CLEAR_ERROR',

  NORMALIZED = 'NORMALIZED',

  GET_WEATHER = 'GET_WEATHER',
  GET_INITIAL_DATA = 'GET_INITIAL_DATA',
  SET_INITIAL_DATA = 'SET_INITIAL_DATA',
  SET_LOADER = 'SET_LOADER',
  SET_CONFIG = 'SET_CONFIG',
  GET_SINGLE_DASH = 'GET_SINGLE_DASH',
  GET_MULTIPLE_ALT = 'GET_MULTIPLE_ALT',
  CLEAN_DISPLAY = 'CLEAN_DISPLAY',
  GET_FLEXIBLE_DATA = 'GET_FLEXIBLE_DATA',
  GET_FLEXIBLE_DATA_SUCCESS = 'GET_FLEXIBLE_DATA_SUCCESS',
  UPDATE_ENDPOINT_OBJECT = 'UPDATE_ENDPOINT_OBJECT',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
}
