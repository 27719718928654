import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { ERRORS } from '../../enums/errors';
import { HttpResponse } from '../../interfaces/api/http.interface';
import { HttpError } from '../../lib/HttpClient';
import { GetFriendlyError, GetFriendlyStatusError, millisDiff } from '../../utils/general';
import { IBackendEndpoint } from '../useDataManager';
import { isEqual } from 'lodash';
const url = process.env.REACT_APP_API_URL as string;

const CACHE_DURATION_MS = 60 * 1000; // 1 minute to avoid duplicated requests

export interface IGetFlexibleData {
  apiProvider?: 'ALSO_ENERGY' | 'SMA' | 'OPEN_METEO';
  endpoint: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: any;
  params?: any;
}

export async function getFlexibleDataAPI(data: IGetFlexibleData) {
  return await axios.post(`${url}/flexible`, data);
}

interface ICalledEndpoint {
  name: string;
  params: any;
  calledAt: Date;
  status?: 'loading' | 'fetched';
}

const alreadyCalledEndpoints: { [key: string]: ICalledEndpoint } = {};

const flexibleSaga = {
  *getFlexibleData(action: any): Generator {
    try {
      const backendEndpoint = action.backendEndpoint as IBackendEndpoint;
      const endpointName = backendEndpoint.endpointName ?? 'general';

      if (
        !backendEndpoint.avoidCache &&
        alreadyCalledEndpoints[endpointName] &&
        isEqual(alreadyCalledEndpoints[endpointName]?.params, backendEndpoint?.fetchPayload?.params) &&
        millisDiff(alreadyCalledEndpoints[endpointName].calledAt) <= CACHE_DURATION_MS
      ) {
        alreadyCalledEndpoints[endpointName] = {
          params: backendEndpoint.fetchPayload.params,
          name: endpointName,
          calledAt: new Date()
        };
        action.onFail && action.onFail(ERRORS.ALREADY_CALLED);
        return;
      }
      const storeObj = { reloading: true, error: undefined, params: backendEndpoint.fetchPayload.params } as any;
      if (!alreadyCalledEndpoints[endpointName]) {
        storeObj.loading = true;
        storeObj.reloading = false;
        storeObj.data = undefined;
      }
      yield put({
        type: ACTIONS.UPDATE_ENDPOINT_OBJECT,
        payload: storeObj,
        endpointName
      });

      alreadyCalledEndpoints[endpointName] = {
        params: backendEndpoint.fetchPayload.params,
        name: endpointName,
        calledAt: new Date()
      };
      const res = (yield call(
        getFlexibleDataAPI,
        backendEndpoint.fetchPayload as IGetFlexibleData
      )) as HttpResponse<unknown>;
      if (res.status === 200 || res.status === 201) {
        yield put({
          type: ACTIONS.GET_FLEXIBLE_DATA_SUCCESS,
          payload: {
            [endpointName]: {
              data: res.data,
              reloading: false,
              loading: false,
              error: undefined,
              params: backendEndpoint.fetchPayload.params
            }
          }
        });
        if (action.onSuccess) {
          action.onSuccess(res.data);
        }
      } else {
        yield put({
          type: ACTIONS.GET_FLEXIBLE_DATA_SUCCESS,
          payload: {
            [endpointName ?? 'general']: {
              data: res.data,
              params: backendEndpoint.fetchPayload.params,
              reloading: false,
              loading: false,
              error: 'HTTP error status'
            }
          }
        });
        action.onFail && action.onFail(res.data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('getFlexibleData() error:', e);
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  }
};

export default flexibleSaga;
