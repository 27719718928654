export const stringErrors: { [key: string]: string } = {
  INVALID_PARAMS: 'Error: Invalid params',
  TOKEN_DOES_NOT_EXIST: "Error: Token Doesn't exist",
  TOKEN_EXPIRED: 'Error: This token has expired',
  UNKNOWN_ERROR: 'Error: An unknown error has ocurred'
};

export const statusErrors: { [key: number]: string } = {
  401: 'You are not authorized to access this',
  404: 'The page you are trying to access was not found',
  500: 'Something bad has happened on our side, we apologize'
};
