/* eslint-disable */
import { useSelector as _useSelector } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { IRootState } from '../interfaces/store/root.interface';
import { initialGraphState } from './reducers/graphReducer';
import reducer from './reducers/index';
import { initialMainState } from './reducers/mainReducer';
import { initialFlexibleState } from './reducers/flexibleReducer';
import rootSaga from './sagas/rootSaga';

const init: IRootState = {
  main: initialMainState,
  graph: initialGraphState,
  flexible: initialFlexibleState
};

export function useSelector<T>(fn: (store: IRootState) => T): T {
  return fn(_useSelector((x: IRootState) => x));
}

export default function store(initialState: IRootState = init) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  return store;
}
