/* eslint-disable no-param-reassign */
import { DISPLAY_CHART_OPTION, GRAPH_ACTIONS } from '../../enums/graph.actions';
import { IGraphState } from '../../interfaces/store/graph.interface';

export const initialGraphState: IGraphState = {
  axisY: {
    min: 0,
    max: 0
  },
  dataGraph: {},
  multipledataGraph: {},
  graphConfig: {},
  multipleGraphConfig: {},
  menu: {
    display: DISPLAY_CHART_OPTION.CHART,
    showAlerts: false,
    showLeyend: true
  }
};

export default (state: any = initialGraphState, action: any): IGraphState => {
  switch (action.type) {
    case GRAPH_ACTIONS.SET_GRAPH_MIN_AXIS_Y:
      return { ...state, axisY: { min: action.payload as number, max: state.axisY.max } };
    case GRAPH_ACTIONS.SET_GRAPH_MAX_AXIS_Y:
      return { ...state, axisY: { min: state.axisY.min, max: action.payload as number } };
    case GRAPH_ACTIONS.GET_GRAPH_SUCCESS:
      return { ...state, dataGraph: { ...action.data } };
    case GRAPH_ACTIONS.SET_MULTIPLE_GRAPHS:
      return { ...state, dataGraph: { ...action.data } };
    case GRAPH_ACTIONS.SET_GRAPH_CONFIG_SUCCESS:
      return { ...state, graphConfig: { ...action.data } };
    case GRAPH_ACTIONS.SET_MULTIPLE_GRAPH_CONFIG_SUCCESS:
      return { ...state, multipleGraphConfig: { ...action.data } };
    case GRAPH_ACTIONS.SET_GRAPH_DISPLAY_OPTION:
      return { ...state, menu: { ...state.menu, display: action.payload, showAlerts: false } };
    case GRAPH_ACTIONS.SHOW_GRAPH_LEYEND:
      return { ...state, menu: { ...state.menu, showLeyend: action.payload } };
    case GRAPH_ACTIONS.SHOW_GRAPH_ALERTS:
      return { ...state, menu: { ...state.menu, showAlerts: action.payload } };
    case GRAPH_ACTIONS.CLEAN_DISPLAY:
      return {
        ...state,
        dataGraph: { ...action.data }
      };
    default:
      return state;
  }
};
