/* eslint-disable no-param-reassign */
import { ACTIONS } from '../../enums/actions';
import { IReduceActionFlexible } from '../../interfaces/store/root.interface';
import { IBackendData } from '../useWidgetDataMultiEndpoint';

export interface IFlexibleState {
  backendData: IBackendData;
  loading: boolean;
}
export const initialFlexibleState: IFlexibleState = {
  backendData: {},
  loading: false
};

export default (state: IFlexibleState = initialFlexibleState, action: IReduceActionFlexible): IFlexibleState => {
  switch (action.type) {
    case ACTIONS.UPDATE_ENDPOINT_OBJECT:
      return {
        ...state,
        backendData: {
          ...state.backendData,
          // @ts-ignore
          [action.endpointName]: {
            // @ts-ignore
            ...(state.backendData[action.endpointName] ?? {}),
            ...action.payload
          }
        },
        loading: true
      };
    case ACTIONS.GET_FLEXIBLE_DATA:
      return {
        ...state,
        loading: true
      };
    case ACTIONS.GET_FLEXIBLE_DATA_SUCCESS:
      return {
        ...state,
        backendData: {
          ...state.backendData,
          ...action.payload
        },
        loading: false
      };
    default:
      return state;
  }
};
