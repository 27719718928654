import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { STORE_VARIABLE } from '../../enums/sessiontorage';
import { HttpResponse } from '../../interfaces/api/http.interface';
import { IDispatchAction } from '../../interfaces/store/root.interface';
import { HttpError } from '../../lib/HttpClient';
import { GetFriendlyError, GetFriendlyStatusError, clientPathQueryParamBuilder } from '../../utils/general';
const url = process.env.REACT_APP_API_URL as string;

interface IgetWeather {
  days: number;
  siteName?: string;
}

interface IgetSingleDashboard {
  siteName?: string;
  id?: any;
}

async function getWeather(data: IgetWeather) {
  if (!data.siteName || data.siteName.length === 0) {
    const siteNameStored = sessionStorage.getItem(STORE_VARIABLE.SITE_NAME) || '';
    const response = await axios.get(`${url}/weather?days=${data.days}&siteName=${siteNameStored}`);
    return response;
  } else {
    const response = await axios.get(`${url}/weather?days=${data.days}&siteName=${data.siteName}`);
    if (response.status === 200) {
      sessionStorage.setItem(STORE_VARIABLE.SITE_NAME, data.siteName);
    }
    return response;
  }
}

async function getMultipleSites(data: unknown, siteName = '') {
  let endpoint = `${url}/sites`;

  if (siteName && siteName.length) {
    endpoint += clientPathQueryParamBuilder(siteName);
  }

  const response = await axios.post(endpoint, data);
  return response;
}

async function getSiteAPI(siteName = '', siteId = '') {
  let endpoint = `${url}/site`;
  const host = window.location.host;
  if (host.includes('localhost') || host.includes('alsoenergy')) {
    if (siteName && siteName.length) {
      endpoint += clientPathQueryParamBuilder(siteName);
    }

    if (siteName && siteName.length === 0) {
      const siteNameStored = sessionStorage.getItem(STORE_VARIABLE.SITE_NAME) || '';
      endpoint += clientPathQueryParamBuilder(siteNameStored);
    }

    if (siteId && siteId.length) {
      endpoint += clientPathQueryParamBuilder(siteId, STORE_VARIABLE.SITE_ID, true);
    }

    if (siteId && siteId.length === 0) {
      const siteIdStored = sessionStorage.getItem(STORE_VARIABLE.SITE_ID) || '';
      endpoint += clientPathQueryParamBuilder(siteIdStored);
    }
  }
  const response = await axios.get(endpoint);
  if (response.status === 200) {
    sessionStorage.setItem(STORE_VARIABLE.SITE_NAME, siteName);
    sessionStorage.setItem(STORE_VARIABLE.SITE_ID, siteId);
  }
  return response;
}

async function getSingleSiteDashboard(data: IgetSingleDashboard) {
  if (!data.siteName || data.siteName.length === 0) {
    const siteNameStored = sessionStorage.getItem(STORE_VARIABLE.SITE_NAME) || '';
    const idStored = sessionStorage.getItem(STORE_VARIABLE.SITE_ID) || '';
    const response = await axios.post(`${url}/custom-site-dashboard/${idStored}`, { siteName: siteNameStored });
    return response;
  } else {
    const response = await axios.post(`${url}/custom-site-dashboard/${data.id}`, { siteName: data.siteName });
    if (response.status === 200) {
      sessionStorage.setItem(STORE_VARIABLE.SITE_NAME, data.siteName);
      sessionStorage.setItem(STORE_VARIABLE.SITE_ID, data.id);
    }
    return response;
  }
}

const mainSaga = {
  *getInitialData(action: any): Generator {
    try {
      const response: any = yield call(getSiteAPI, action.payload.siteName, action.payload.siteId);
      if (response.status === 200 || response.status === 201) {
        const singleSiteCondition = response.data && response.data.config;
        const multipleSitesCondition = response.data && Array.isArray(response.data) && response.data[0].config;

        if (singleSiteCondition && response.data.config.tabTitle) {
          document.title = response.data.config.tabTitle;
        }
        if (multipleSitesCondition && response.data[0].config.tabTitle) {
          document.title = response.data[0].config.tabTitle;
        }
        if (singleSiteCondition && response.data.config.icon) {
          const favicon: any = document.getElementById('favicon');
          favicon.href = `${url}/${response.data.config.icon}`;
        }
        if (multipleSitesCondition && response.data[0].config.icon) {
          const favicon: any = document.getElementById('favicon');
          favicon.href = `${url}/${response.data[0].config.icon}`;
        }

        if (multipleSitesCondition) {
          yield put({ type: ACTIONS.GET_MULTIPLE_ALT, payload: response.data });
        } else {
          yield put({ type: ACTIONS.SET_INITIAL_DATA, payload: response.data });
        }

        if (action.onSuccess) {
          if (Array.isArray(response.data) && response.data[0].config) action.onSuccess(response.data[0]);
          else action.onSuccess(response.data);
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *getWeather(action: any): Generator {
    try {
      const res: any = yield call(getWeather, action.payload);

      if (res.status === 200 || res.status === 201) {
        if (action.onSuccess) {
          yield put({ type: ACTIONS.GET_WEATHER, payload: res.data });
          action.onSuccess(res.data);
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *getSingleData(action: any): Generator {
    try {
      const res: any = yield call(getSingleSiteDashboard, action.payload);
      if (res.status === 200 || res.status === 201) {
        if (action.onSuccess) {
          yield put({ type: ACTIONS.GET_SINGLE_DASH, payload: res.data });
          action.onSuccess(res.data);
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *exampleSagaCall(action: IDispatchAction): Generator {
    try {
      const payload = action.payload;
      const response = (yield call(getMultipleSites, payload)) as HttpResponse<unknown>;
      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
          yield put({ type: 'EXAMPLE_SUCCESS', data: {} });
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  }
};

export default mainSaga;
