import { Box, CircularProgress } from "@mui/material";

const Loader = (
  <>
    <Box
      component="main"
      className='main-loading'
      sx={{
        flexGrow: 1, width: `99.5vw`, height: '94vh', display: 'flex', alignContent: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}
    >
      <CircularProgress />
    </Box>
  </>
);

export default Loader;