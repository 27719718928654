import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { STORE_VARIABLE } from '../../enums/sessiontorage';
import sitesConfigList from '../../sites/sites';

interface IReturn {
  get: () => string | undefined;
}

export default function useSiteName(): IReturn {
  const [searchParams] = useSearchParams();
  const siteNameSearchParam = searchParams.get(STORE_VARIABLE.SITE_NAME);
  const { config } = useSelector<any, any>((state: any) => state.main as any);

  const location = window.location.hostname;

  const set = (siteName: string) => {
    sessionStorage.setItem(STORE_VARIABLE.SITE_NAME, siteName);
  };

  const get = () => {
    if (siteNameSearchParam) {
      set(siteNameSearchParam);
      return siteNameSearchParam;
    } else if (config && config.name) {
      set(config.name);
      return config.name as string;
    } else if (!location.includes('localhost') && !location.includes('alsoenergy')) {
      const siteName = sitesConfigList.find((site: any) => location === site.hostname)?.name;
      if (siteName) {
        set(siteName);
        return siteName;
      } else {
        const storedSiteName = sessionStorage.getItem(STORE_VARIABLE.SITE_NAME);
        return storedSiteName ?? undefined;
      }
    } else {
      const storedSiteName = sessionStorage.getItem(STORE_VARIABLE.SITE_NAME);
      return storedSiteName ?? undefined;
    }
  };

  return { get };
}
