import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { GRAPH_ACTIONS } from '../../enums/graph.actions';
import { HttpError } from '../../lib/HttpClient';
import { GetFriendlyError, GetFriendlyStatusError } from '../../utils/general';

const url = process.env.REACT_APP_API_URL as string;

interface IgetGraphAPI {
  chartId: number;
  startTime: Date;
  endTime: Date;
  span: string;
  bin: string;
}

interface IgetGraphConfigAPI {
  chartId: number;
  siteId: number;
}

interface IgetMultipleGraphs {
  hardwareId: any;
  lineType: string;
  chartId: number;
  startTime: Date;
  endTime: Date;
  span: string;
  bin: string;
  showNetValues: boolean;
}

interface IgetMultipleGraphConfigAPI {
  chartId?: number;
  sites: any;
}

async function getGraphAPI(data: IgetGraphAPI) {
  const response = await axios.post(`${url}/chart-data`, data);
  return response;
}

async function getGraphConfigAPI(data: IgetGraphConfigAPI) {
  const response = await axios.get(`${url}/chart?siteId=${data.siteId}&chartId=${data.chartId}`);
  return response;
}

async function getMultipleGraphsAPI(data: IgetMultipleGraphs) {
  const response = await axios.post(`${url}/custom-chart-data`, data);
  return response;
}

async function getMultipleConfigGraphsAPI(data: IgetMultipleGraphConfigAPI) {
  const response = await axios.post(`${url}/multiple-charts?chartId=${data.chartId}`, { sites: data.sites });
  return response;
}

const graphSaga = {
  *getGraph(action: any): Generator {
    try {
      const response: any = yield call(getGraphAPI, action.payload.type);

      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
          yield put({ type: GRAPH_ACTIONS.GET_GRAPH_SUCCESS, data: response.data });
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *getGraphConfig(action: any): Generator {
    try {
      const response: any = yield call(getGraphConfigAPI, action.payload);

      if (response.status === 200 || response.status === 201) {
        yield put({ type: GRAPH_ACTIONS.SET_GRAPH_CONFIG_SUCCESS, data: response.data });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *getMultipleGraphConfig(action: any): Generator {
    try {
      const response: any = yield call(getMultipleConfigGraphsAPI, action.payload);

      if (response.status === 200 || response.status === 201) {
        yield put({ type: GRAPH_ACTIONS.SET_MULTIPLE_GRAPH_CONFIG_SUCCESS, data: response.data });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  },
  *getMultipleGraphs(action: any): Generator {
    try {
      const response: any = yield call(getMultipleGraphsAPI, action.payload.type);

      if (response.status === 200 || response.status === 201) {
        if (action.onSuccess) {
          action.onSuccess();
          yield put({ type: GRAPH_ACTIONS.SET_MULTIPLE_GRAPHS, data: response.data });
        }
      }
    } catch (e) {
      let friendlyError;
      if (e instanceof HttpError) {
        const error = e as HttpError;
        const parsedErrorMessage = JSON.parse(error.message);
        if (parsedErrorMessage.code === 400) {
          friendlyError = GetFriendlyError(parsedErrorMessage.message);
        } else {
          friendlyError = GetFriendlyStatusError(parsedErrorMessage.code);
        }
        yield put({ type: ACTIONS.ERROR, data: friendlyError });
      } else {
        const error = e as Error;
        friendlyError = GetFriendlyError(error.message);
      }
      yield put({ type: ACTIONS.ERROR, data: friendlyError });
    }
  }
};

export default graphSaga;
