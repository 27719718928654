import { combineReducers } from 'redux';
import { IRootState } from '../../interfaces/store/root.interface';
import graph from './graphReducer';
import main from './mainReducer';
import flexible from './flexibleReducer';

const rootReducer = combineReducers<IRootState>({
  main,
  graph,
  flexible
});

export default rootReducer;
