import {
  EQUIVALENCES_TOOLTIP_CARBON,
  EQUIVALENCES_TOOLTIP_GAS,
  EQUIVALENCES_TOOLTIP_HOME,
  EQUIVALENCES_TOOLTIP_TITLE,
  EQUIVALENCES_TOOLTIP_TREES
} from './constants';
import { EQUIVALENCES } from '../types';

export const getCardIconName = (cardName: string) => {
  switch (cardName) {
    case 'Cars':
      return 'car-solid';

    case 'Trees':
      return 'pagelines-brands';

    case 'Homes':
      return 'house-chimney-solid';

    case 'Natural Gas':
      return 'natural-gas-outlined';

    case 'Carbon':
      return 'industry-solid';

    case 'Gas':
      return 'gas-pump-solid';

    case 'Water':
      return 'water-outlined';

    case 'Methane':
      return 'methane-outlined';

    case 'Carbon Dioxide':
      return 'carbon-dioxide';

    case 'Flow Meters':
      return 'flow-meters';

    default:
      return '';
  }
};

export const getTooltipMessage = (equivalenceName: string) => {
  switch (equivalenceName.toLowerCase()) {
    case 'trees':
      return EQUIVALENCES_TOOLTIP_TREES;
    case 'carbon':
      return EQUIVALENCES_TOOLTIP_CARBON;
    case 'gas':
      return EQUIVALENCES_TOOLTIP_GAS;
    case 'homes':
      return EQUIVALENCES_TOOLTIP_HOME;
    case 'main-title':
      return EQUIVALENCES_TOOLTIP_TITLE;
    default:
      return '';
  }
};

export function whToEquivalence(Wh: number, equivalence: EQUIVALENCES): number {
  const kWh = Wh / 1000; // Convert Wh to kWh

  switch (equivalence) {
    case EQUIVALENCES.HOMES:
      return kWh / 30;

    case EQUIVALENCES.CARBON:
    case EQUIVALENCES.CARBONDIOXIDE: // Assuming Carbon Dioxide is equivalent to Carbon here
      return (kWh * 85230) / 100000;

    case EQUIVALENCES.GAS:
      return kWh / 33.7;

    case EQUIVALENCES.TREES:
      // Assuming a placeholder calculation since no specific conversion is provided for Trees.
      // Replace with the actual conversion factor if available.
      // const treesPerKWh = 0.017; // Example: 1 kWh could save 0.017 trees
      return kWh * 0.017;

    default:
      throw new Error('Invalid equivalence type');
  }
}

// export function whToEquivalence(whGenerated: number, equivalenceType: EQUIVALENCES): number {
//   const averageMonthlyConsumption = 877 * 1000; // Wh per month per home
//   const co2PerWh = 0.92 / 1000; // lbs of CO2 per Wh
//   const co2PerGallon = 19.6; // lbs of CO2 per gallon of gasoline
//
//   switch (equivalenceType) {
//     case EQUIVALENCES.HOMES:
//       return whGenerated / averageMonthlyConsumption;
//
//     case EQUIVALENCES.CARBON:
//       return whGenerated * co2PerWh;
//
//     case EQUIVALENCES.GAS:
//       return (whGenerated * co2PerWh) / co2PerGallon;
//
//     default:
//       return 0;
//   }
// }
