import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { HttpResponse, HttpStatus, RouteMethod } from '../interfaces/api/http.interface';
import { JwtPayload } from '../interfaces/api/jwt.interface.ts';

const API_URL: string = process.env.REACT_APP_API_HOST || 'http://localhost:3001/api';

export class HttpError extends Error {
  constructor(message: string) {
    super(message); // (1)
    this.name = 'HttpError'; // (2)
  }
}

export const objectToQuery = (obj: any) => {
  return (
    '?' +
    Object.keys(obj)
      .map((key) => key + '=' + obj[key])
      .join('&')
  );
};

export default class HttpClient {
  private url: string;

  private token: string;

  private publicRoutes: RouteMethod[] = [];

  private refreshToken: string;

  private currentRoute: string;

  private interceptor: boolean;

  private axios = axios.create();

  constructor(service: string) {
    this.refreshToken = localStorage.refresh_token as string;
    this.token = localStorage.jwt as string;
    this.url = `${API_URL}/${service}`;
    this.currentRoute = '';
    this.interceptor = true;
    this.axios.interceptors.request.use(this.requestInterceptor.bind(this));
  }

  private async requestInterceptor(config: AxiosRequestConfig) {
    if (this.interceptor) {
      if (this.publicRoutes.length > 0) {
        const isRoutePublic = !!this.publicRoutes.find(
          (r) => r.route === this.currentRoute && r.method === config.method
        );
        if (isRoutePublic) {
          return config;
        }
      }

      if (!this.token && !localStorage.jwt) {
        return config;
      }
      if (!this.token) {
        this.token = localStorage.jwt as string;
      }

      /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion */
      const payload = jwtDecode(this.token) as JwtPayload;
      /* eslint-enable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unnecessary-type-assertion */
      if (new Date().getTime() / 1000 + 5 >= payload.exp) {
        const newAxiosInstance = axios.create();
        try {
          const response = await newAxiosInstance.put<{
            data: { jwt: string };
            errors: unknown[];
          }>(`${API_URL}/auth/refresh-jwt`, {
            // eslint-disable-next-line camelcase
            refresh_token: this.refreshToken
          });
          const { jwt } = response.data.data;
          localStorage.setItem('jwt', jwt);
          this.token = jwt;
        } catch (e) {
          const error = JSON.stringify({
            message: 'There has been an issue with your credentials, please re-authenticate to obtain access again.',
            code: 401
          });
          throw new HttpError(error);
        }
      }

      if (!config.headers) {
        config.headers = { Authorization: this.token };
      } else {
        (config.headers as Record<string, unknown>).Authorization = this.token;
      }
    } else {
      this.interceptor = true;
    }
    return config;
  }

  public setPublicRoutes(routes: RouteMethod[]): void {
    this.publicRoutes = routes;
  }

  public deleteContentTypeHeader(): void {
    delete this.axios.defaults.headers.put['Content-Type'];
  }

  async get<Data>(route?: string, options?: AxiosRequestConfig, interceptor = true): Promise<HttpResponse<Data>> {
    this.interceptor = interceptor;
    this.currentRoute = route ?? '';
    const endpoint = route ? `${this.url}/${route}` : this.url;
    try {
      const response = await this.axios.get<Data>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      const exceptionError = JSON.stringify({
        message: error.response?.data.error,
        code: error.response?.status
      });
      throw new HttpError(exceptionError);
    }
  }

  async post<Data>(
    route?: string,
    data?: unknown,
    options?: AxiosRequestConfig,
    interceptor = true
  ): Promise<HttpResponse<Data>> {
    this.interceptor = interceptor;
    this.currentRoute = route ?? '';
    const endpoint = route ? `${this.url}/${route}` : this.url;
    try {
      const response = await this.axios.post<Data>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      const exceptionError = JSON.stringify({
        message: error.response?.data.error,
        code: error.response?.status
      });
      throw new HttpError(exceptionError);
    }
  }

  async patch<Data>(
    route?: string,
    data?: unknown,
    options?: AxiosRequestConfig,
    interceptor = true
  ): Promise<HttpResponse<Data>> {
    this.interceptor = interceptor;
    this.currentRoute = route ?? '';
    const endpoint = route ? `${this.url}/${route}` : this.url;
    try {
      const response = await this.axios.patch<Data>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      const exceptionError = JSON.stringify({
        message: error.response?.data.error,
        code: error.response?.status
      });
      throw new HttpError(exceptionError);
    }
  }

  async put<Data>(
    route?: string,
    data?: unknown,
    options?: AxiosRequestConfig,
    interceptor = true
  ): Promise<HttpResponse<Data>> {
    this.interceptor = interceptor;
    this.currentRoute = route ?? '';
    const endpoint = route ? `${this.url}/${route}` : this.url;
    try {
      const response = await this.axios.put<Data>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      const exceptionError = JSON.stringify({
        message: error.response?.data.error,
        code: error.response?.status
      });
      throw new HttpError(exceptionError);
    }
  }

  async delete<Data>(route?: string, options?: AxiosRequestConfig, interceptor = true): Promise<HttpResponse<Data>> {
    this.interceptor = interceptor;
    this.currentRoute = route ?? '';
    const endpoint = route ? `${this.url}/${route}` : this.url;
    try {
      const response = await this.axios.delete<Data>(endpoint, options);
      return {
        status: response.status,
        data: response.data
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      const exceptionError = JSON.stringify({
        message: error.response?.data.error,
        code: error.response?.status
      });
      throw new HttpError(exceptionError);
    }
  }
}
