/* eslint-disable no-param-reassign */
import { ACTIONS } from '../../enums/actions';
import { IMainState } from '../../interfaces/store/main.interface';
import { IReduceAction } from '../../interfaces/store/root.interface';

export const initialMainState: IMainState = {
  data: {},
  siteData: {},
  singleSiteData: {},
  config: {},
  multipleSites: [],
  configSum: {},
  siteDataSum: {},
  weatherInfo: {},
  forecast: [],
  loader: false,
  singleDashboardConfig: {},
  singleDashboardData: {},
  language: "EN"
};

export default (state: IMainState = initialMainState, action: IReduceAction): IMainState => {
  switch (action.type) {
    case ACTIONS.CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.language
      };
    case ACTIONS.SET_INITIAL_DATA:
      return {
        ...state,
        siteData: action.payload,
        singleSiteData: action.payload,
        config: action.payload.config,
        loader: false
      };
    case ACTIONS.GET_WEATHER:
      return {
        ...state,
        forecast: action.payload.forecast,
        weatherInfo: action.payload,
        loader: false
      };
    case ACTIONS.GET_MULTIPLE_ALT:
      return {
        ...state,
        siteData: action.payload[0],
        config: action.payload[0].config,
        multipleSites: action.payload,
        siteDataSum: action.payload[0],
        configSum: action.payload[0].config,
        loader: false
      };
    case ACTIONS.SET_LOADER:
      return { ...state, loader: !state.loader };
    case ACTIONS.GET_SINGLE_DASH:
      return {
        ...state,
        singleDashboardData: action.payload,
        singleDashboardConfig: action.payload.config,
        loader: false
      };
    case ACTIONS.CLEAN_DISPLAY:
      return {
        ...state,
        singleSiteData: action.payload,
        singleDashboardData: action.payload,
        singleDashboardConfig: action.payload.config
      };
    default:
      return state;
  }
};
