import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense, lazy, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import useSiteName from './lib/hooks/useSiteName';
import Loader from './templates/Loader';
import generateTheme from './theme/mui-theme';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

const NoSiteFound = lazy(() => import('./pages/NoSiteFound'));
const PittsburgSiteTemplateApp = lazy(() => import('./sites/pittsburg-site-template/App'));
const DefaultSiteApp = lazy(() => import('./DefaultSiteApp'));
const BuckinghamCompaniesApp = lazy(() => import('./sites/buckingham-companies/App'));
const EndressApp = lazy(() => import('./sites/endress/App'));
const FlorenceApp = lazy(() => import('./sites/florence/App'));
const PhilExpoCenterApp = lazy(() => import('./sites/phil-expo-center/App'));
const RollsRoyceApp = lazy(() => import('./sites/rolls-royce/App'));
const WinonaApp = lazy(() => import('./sites/winona/App'));
const PitCompilationApp = lazy(() => import('./sites/pit-compilation/App'));
const CMTASiteTemplateApp = lazy(() => import('./sites/cmta-site-template/App'));
const SitesInfo = lazy(() => import('./pages/SitesInfo'));
const LockheedMartinSiteTemplateApp = lazy(() => import('./sites/lockheed-martin/App'));
const WaateekaaSiteTemplateApp = lazy(() => import('./sites/waateekaa/App'));
const KokosingSiteTemplateApp = lazy(() => import('./sites/kokosing/App'));

const SiteSelector = () => {
  const siteName = useSiteName().get();

  switch (siteName) {
    case 'phil-expo-center':
      return <PhilExpoCenterApp />;
    case 'buckingham-companies':
      return <BuckinghamCompaniesApp />;
    case 'florence':
      return <FlorenceApp />;
    case 'winona':
      return <WinonaApp />;
    case 'endress':
      return <EndressApp />;
    case 'rolls-royce':
      return <RollsRoyceApp />;
    case 'pit-black-diamond':
    case 'foothill':
    case 'martin-luther-king':
    case 'hillview':
    case 'rancho':
    case 'heights':
    case 'highlands':
    case 'los-medanos':
    case 'marina':
    case 'pit-unified':
    case 'parkside':
    case 'stoneman':
    case 'pit-adult':
    case 'willow':
      return <PittsburgSiteTemplateApp />;
    case 'pit-compilation':
      return <PitCompilationApp />;
    case 'discovery':
    case 'frederick-douglass':
    case 'j-lumpton-simpson':
    case 'madison-trust':
    case 'steauart':
    case 'cardinal':
    case 'catoctin':
    case 'creighton':
    case 'hovatter':
    case 'lucketts':
    case 'moorefield':
    case 'broadrun':
    case 'evergreen':
      return <CMTASiteTemplateApp />;
    case 'lockheed-martin':
      return <LockheedMartinSiteTemplateApp />;
    case 'waateekaa':
      return <WaateekaaSiteTemplateApp />;
    case 'kokosing':
      return <KokosingSiteTemplateApp />;
    case 'sites-info':
      return <SitesInfo />;
    case 'default':
      return <DefaultSiteApp />;
    default:
      return <NoSiteFound />;
  }
};

const App: React.FC = () => {
  const theme = useMemo(() => generateTheme(), []);
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={Loader}>
          <QueryClientProvider client={queryClient}>
            <SiteSelector />
          </QueryClientProvider>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
