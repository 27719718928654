import { takeEvery, takeLeading } from 'redux-saga/effects';
import { ACTIONS } from '../../enums/actions';
import { GRAPH_ACTIONS } from '../../enums/graph.actions';
import graphSaga from './graphSaga';
import mainSaga from './mainSaga';
import flexibleSaga from './flexibleSaga';

export default function* rootSaga() {
  yield takeEvery(ACTIONS.GET_SINGLE_DASH, mainSaga.getSingleData);
  yield takeLeading(GRAPH_ACTIONS.GET_GRAPH, graphSaga.getGraph);
  yield takeLeading(ACTIONS.GET_INITIAL_DATA, mainSaga.getInitialData);
  yield takeEvery(ACTIONS.GET_FLEXIBLE_DATA, flexibleSaga.getFlexibleData);
  yield takeLeading(ACTIONS.GET_WEATHER, mainSaga.getWeather);
  yield takeEvery(GRAPH_ACTIONS.SET_MULTIPLE_GRAPHS, graphSaga.getMultipleGraphs);
  yield takeEvery(GRAPH_ACTIONS.SET_CHART_CONFIG, graphSaga.getGraphConfig);
  yield takeEvery(GRAPH_ACTIONS.SET_MULTIPLE_GRAPH_CONFIG_SUCCESS, graphSaga.getMultipleGraphConfig);
}
