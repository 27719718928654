export enum GRAPH_ACTIONS {
  SET_GRAPH_MIN_AXIS_Y = 'SET_GRAPH_MIN_AXIS_Y',

  SET_GRAPH_MAX_AXIS_Y = 'SET_GRAPH_MAX_AXIS_Y',

  GET_GRAPH = 'GET_GRAPH',

  SET_CHART_CONFIG = 'SET_CHART_CONFIG',

  GET_GRAPH_SUCCESS = 'GET_GRAPH_SUCCESS',

  SET_GRAPH_CONFIG_SUCCESS = 'SET_GRAPH_CONFIG_SUCCESS',

  SET_MULTIPLE_GRAPH_CONFIG_SUCCESS = 'SET_MULTIPLE_GRAPH_CONFIG_SUCCESS',

  SET_MULTIPLE_GRAPHS = 'SET_MULTIPLE_GRAPHS',

  SET_GRAPH_DISPLAY_OPTION = 'SET_GRAPH_DISPLAY_OPTION',

  SHOW_GRAPH_LEYEND = 'SHOW_GRAPH_LEYEND',

  SHOW_GRAPH_ALERTS = 'SHOW_GRAPH_ALERTS',

  CLEAN_DISPLAY = 'CLEAN_DISPLAY'
}

export enum DISPLAY_CHART_OPTION {
  CHART = 'CHART',
  TABLE = 'TABLE'
}
